import React, { useContext } from "react"
import { Link } from "gatsby"
import {
  Button,
  Box,
  Heading,
  Grid,
  FormField,
  Form,
  TextArea,
  TextInput,
  Paragraph,
  ResponsiveContext,
} from "grommet"
import { MailOption, StatusGood } from "grommet-icons"

const defaultValue = {
  name: "",
  email: "",
  problem: "",
  outcome: "",
  timeframe: "",
  summary: "",
}

const ContactForm = (data) => {
  const size = useContext(ResponsiveContext)
  const [value, setValue] = React.useState(defaultValue)
  const [contactFormSubmitted, setContactFormSubmitted] = React.useState(false)

  const formSubmit = event => {
    const submit = `&submit=SUBMIT`
    const formResponse = `formResponse`
    const makeUrl = `https://docs.google.com/forms/d/e/1FAIpQLSeP8QA81BNaT4d8k9iA1Zt0_zeDUE1UNowZNVcEpxPUIsEX3A/${formResponse}?usp=pp_url&entry.611518217=${encodeURIComponent(
      event.value.name
    )}&entry.41026121=${encodeURIComponent(
      event.value.email
    )}&entry.1309701181=${encodeURIComponent(
      event.value.problem
    )}&entry.57961100=${encodeURIComponent(
      event.value.outcome
    )}&entry.1677910359=${encodeURIComponent(
      event.value.timeframe
    )}&entry.913354900=${encodeURIComponent(event.value.summary)}&entry.1018157540=location${submit}`

    fetch(makeUrl, {
      method: "GET", // or 'PUT'
      mode: "no-cors",
      redirect: "follow",
      referrer: "no-referrer",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then()
      .then(response => {
        setContactFormSubmitted(true)
      })
  }

  const ContactForm = (
    <Form
      value={value}
      onChange={nextValue => {
        setValue(nextValue)
      }}
      onSubmit={(event) => formSubmit(event)}
    >
      <Grid
        columns={{
          count: size === "small" ? "fit" : 2,
          size: size === "small" ? "100%" : ["auto", "auto"],
        }}
        gap="small"
      >
        <FormField
          required
          htmlFor="name"
          id="name"
          name="name"
          label="Name"
          margin={{ vertical: "none" }}
          component={TextInput}
          placeholder="type here"
        />
        <FormField
          htmlFor="email"
          margin={{ vertical: "none" }}
          label="Email address"
        >
          <TextInput
            required
            id="email"
            name="email"
            type="email"
            label="Email address"
            icon={<MailOption />}
          />
        </FormField>
      </Grid>
      <FormField
        htmlFor="problem"
        id="problem"
        name="problem"
        label="What problem do you want to solve?"
        margin={{ top: "small" }}
        component={TextArea}
        placeholder="type here"
      />
      <FormField
        htmlFor="outcome"
        id="outcome"
        name="outcome"
        label="What outcome would you like to achieve?"
        margin={{ top: "small" }}
        component={TextArea}
        placeholder="type here"
      />
      <FormField
        htmlFor="timeframe"
        id="timeframe"
        name="timeframe"
        label="What time frame are you working to?"
        margin={{ top: "small" }}
        component={TextArea}
        placeholder="type here"
      />
      <FormField
        htmlFor="summary"
        id="summary"
        margin={{ top: "small" }}
        name="summary"
        component={TextArea}
        label="Not sure? Give us a summary."
        placeholder="type here"
      />
      <Paragraph>
        Please read our <Link to="/privacy">Privacy Policy</Link> to see how we
        use your personal information.
      </Paragraph>
      <Button size="large" type="submit" label="Submit" primary color="brand" />
    </Form>
  )

  const ContactFormSucess = (
    <Box
      border={{
        color: "brand",
        size: "medium",
        style: "solid",
        side: "all",
      }}
    >
      <Box width="auto" alignSelf="start" background="brand" pad="small">
        <StatusGood color="black" size="large" />
      </Box>
      <Box pad="large">
        <Heading margin={{ vertical: "none" }}>Thank you </Heading>
        <Paragraph>
          We've received your message and we'll get back to you shortly. Whilst
          you're waiting, why not check out our journal?
        </Paragraph>
        <Box direction="row" justify="start" gap="medium">
          <Link to="/journal">
            <Button primary>View journal</Button>
          </Link>
        </Box>
      </Box>
    </Box>
  )

  return (
    <Box fill>{contactFormSubmitted ? ContactFormSucess : ContactForm}</Box>
  )
}

export default ContactForm
