import React from "react"
import { Box, Heading } from "grommet"
import Seo from "../components/seo"
import Layout from "../components/layout"
import ContactForm from "../components/contactForm"

export default function Contact({location}) {


  return (
    <Layout>
      <Seo title="Contact us" />
      <Box>
        <Box
          width="large"
          alignSelf="center"
          align="start"
          pad={{horizontal: "medium"}}
          margin={{ bottom: "xlarge" }}
        >
          <Heading
            className="hero"
            margin={{ top: "xlarge", bottom: "xsmall" }}
            level="1"
            size="medium"
          >
            Let's talk
          </Heading>
          <ContactForm  />
        </Box>
      </Box>
    </Layout>
  )
}
